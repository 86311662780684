import React from "react";
import {Outlet} from "react-router-dom";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";

const RootLayout = () => {
    return <>
        <Header/>
        <main>
            <Outlet/>
        </main>
        <Footer/>
    </>
};
export default RootLayout;